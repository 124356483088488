import { commonInlineStyleHeaderHeadline, useBlocker, BlockerHeaderInlineStyles } from "../../..";

const BlockerHeader = ({
  closeIcon
}) => {
  const blocker = useBlocker();
  const {
    blocker: {
      name
    },
    texts: {
      blockerHeadline
    }
  } = blocker;
  return h("div", BlockerHeaderInlineStyles.headerContainer(blocker), h("div", BlockerHeaderInlineStyles.header(blocker), h("div", commonInlineStyleHeaderHeadline(blocker, !!closeIcon), blockerHeadline.replace(/{{name}}/g, name)), closeIcon));
};

export { BlockerHeader };