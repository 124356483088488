import { HTML_ATTRIBUTE_BLOCKER_CONNECTED, HTML_ATTRIBUTE_COOKIE_IDS, HTML_ATTRIBUTE_RESET_PARENT, HTML_ATTRIBUTE_RESET_PARENT_VALUE } from "@devowl-wp/headless-content-unblocker";
/**
 * In some cases it is necessary to reset some styles which are not resettable through
 * the `style` attribute. E.g. pseudo-classes like `::before` and `::after`.
 */

function applyContentBlockRatioCssPlainCss() {
  const element = document.createElement("style");
  element.style.type = "text/css";
  document.getElementsByTagName("head")[0].appendChild(element);
  const selectorResetParentAttribute = "".concat(HTML_ATTRIBUTE_RESET_PARENT, "=\"").concat(HTML_ATTRIBUTE_RESET_PARENT_VALUE, "\"");
  const selectorVisibleContentBlocker = "[".concat(HTML_ATTRIBUTE_BLOCKER_CONNECTED, "][").concat(HTML_ATTRIBUTE_COOKIE_IDS, "]");
  const selectorContentBlocker = ".rcb-content-blocker";
  const rules = [// Completely deactivate ::before pseudo element
  ...[// Thrive Architect
  ".thrv_wrapper[".concat(selectorResetParentAttribute, "]")].map(s => "".concat(s, "::before{display:none!important;}")), // Completely hide element
  ...[// JetElements for Elementor
  ".jet-video[".concat(selectorResetParentAttribute, "]>.jet-video__overlay"), // Divi Page Builder
  ".et_pb_video[".concat(selectorResetParentAttribute, "]>.et_pb_video_overlay"), // legacy
  "".concat(selectorContentBlocker, "+div+.et_pb_video_overlay"), // Ultimate Video (WP Bakery Page Builder)
  "".concat(selectorContentBlocker, "+.ultv-video"), // Ultimate Addons for Elementor
  "".concat(selectorContentBlocker, "+.elementor-widget-container"), // Astra Theme (Gutenberg Block)
  ".wp-block-embed__wrapper[".concat(selectorResetParentAttribute, "]>.ast-oembed-container"), // WP Grid Builder
  "".concat(selectorContentBlocker, "+.wpgb-facet"), // tagDiv Composer
  "".concat(selectorContentBlocker, "+.td_wrapper_video_playlist"), // WP YouTube Lyte
  "".concat(selectorContentBlocker, "+div[class^=\"lyte-\"]"), // Elementor video image overlay
  ".elementor-fit-aspect-ratio[".concat(selectorResetParentAttribute, "]>.elementor-custom-embed-image-overlay")].map(s => "".concat(s, "{display:none!important;}")), // Completely disable padding-top
  ...[// Gutenberg
  ".wp-block-embed__wrapper[".concat(selectorResetParentAttribute, "]::before"), // WP Bakery
  ".wpb_video_widget[".concat(selectorResetParentAttribute, "] .wpb_video_wrapper")].map(s => "".concat(s, "{padding-top:0!important;}")), // Other rules: If you add a similar one, please merge to an array!
  // Thrive Architect
  ".tve_responsive_video_container[".concat(selectorResetParentAttribute, "]{padding-bottom:0!important;}"), // Reset absolute positioning
  ...[// X Pro
  ".x-frame-inner[".concat(selectorResetParentAttribute, "]>div.x-video"), // Avia Builder
  ".avia-video[".concat(selectorResetParentAttribute, "] .avia-iframe-wrap")].map(s => "".concat(s, "{position:initial!important;}")), // Completely disable background
  ...[// JetElements for Elementor
  ".jet-video[".concat(selectorResetParentAttribute, "]")].map(s => "".concat(s, "{background:none!important;}")), // Completely disable border radius for content blocker within a container which can not be handled correctly
  ...[// Thrive Architect
  ".tve_responsive_video_container[".concat(selectorResetParentAttribute, "]")].map(s => "".concat(s, " .rcb-content-blocker > div > div > div {border-radius:0!important;}")), // Completely grow the content blocker in a flex box container
  ...[// Elementor
  ".elementor-widget-wrap>".concat(selectorVisibleContentBlocker)].map(s => "".concat(s, "{flex-grow:1;}"))];
  element.innerHTML = rules.join("");
}

export { applyContentBlockRatioCssPlainCss, HTML_ATTRIBUTE_RESET_PARENT, HTML_ATTRIBUTE_RESET_PARENT_VALUE };